import Codingperson from './assets/animations/codingperson.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Profeciency from './assets/animations/profeciency.jsx';
import Email from './assets/animations/email.jsx';
import {hero, experience, proficiency, posts, contact} from "./config.js";
import { faWhmcs, faJs, faNode, faNpm, faCloudflare, faAws, faPython, faDocker, faGithub, faReact, faLinkedin, faDiscord } from '@fortawesome/free-brands-svg-icons';



function App() {
  return (
    
    <div className="bg-white dark:bg-zinc-900  duration-[1000ms]">
    <div className="blob relative isolate px-6 pt-14 lg:px-8">
      <div
        className="absolute pointer-events-none
        inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative pointer-events-none
          left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#4c00ff] to-[#e83838] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <section id="about">
    <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="animate-typing overflow-hidden whitespace-nowrap border-r-2 max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-purple-600">{hero.Greeting}<br />{hero.Name}</h1>
            <p className="max-w-2xl mb-6 bold dark:font-light text-gray-700 dark:text-gray-300 lg:mb-8 md:text-lg lg:text-xl ">{hero.description}</p>
            <a href="#" className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-purple-700 hover:bg-purple-600 dark:hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 dark:focus:ring-purple-900">
                Contact Me
                <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </a>
        </div>
        <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
          <Codingperson/>
        </div>                
    </div>
</section>
<section id="experience">
  <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
      <div className="max-w-screen-md mb-8 lg:mb-16">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 text-purple-600">My experience</h2>
          <p className="text-gray-700 sm:text-xl dark:text-gray-400">{experience.Description}</p>
      </div>
      <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
  {experience.experiences.map(({ svg, fill,experienceName, experiencedescription }) => (
    <div key={experienceName}>
      <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12" /><svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={fill} viewBox="0 0 24 24"  dangerouslySetInnerHTML={{ __html: svg }} ></svg>
      <h3 className="mb-2 text-xl font-bold text-purple-600">{experienceName}</h3>
      <p className="text-gray-700 dark:text-gray-400">{experiencedescription}</p>
    </div>
  ))}
</div>

  </div>
  <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 text-purple-600">My TechStack</h2>
          <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                <FontAwesomeIcon class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" style={{ color: 'gray' }} icon={faWhmcs} />
                <FontAwesomeIcon class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" style={{ color: 'gray' }} icon={faJs} />       
                <FontAwesomeIcon class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" style={{ color: 'gray' }} icon={faNode} />     
                <FontAwesomeIcon class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" style={{ color: 'gray' }} icon={faNpm} />
                <FontAwesomeIcon class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" style={{ color: 'gray' }} icon={faAws} />
                <FontAwesomeIcon class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" style={{ color: 'gray' }} icon={faPython} />
                <FontAwesomeIcon class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" style={{ color: 'gray' }} icon={faDocker} />
                <FontAwesomeIcon class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" style={{ color: 'gray' }} icon={faCloudflare} />
                <FontAwesomeIcon class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" style={{ color: 'gray' }} icon={faGithub} />
                <FontAwesomeIcon class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" style={{ color: 'gray' }} icon={faReact} />
        </div>
        </div>
</section>
<section id="skills">
<div className="grid max-w-screen-xl px-4 mx-auto lg:gap-8 xl:gap-0 lg:grid-cols-12">
  <div className="mr-auto place-self-center lg:col-span-7">
    <h1 className="max-w-2xl mb-4 text-4xl tracking-tight font-extrabold text-gray-900 text-purple-600">Proficiency</h1>
    <p className="max-w-2xl mb-6 bold dark:font-light text-gray-700 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-200">
     {proficiency.Description}
    </p>
    {proficiency.skill.map(({ name, percentage }) => (
  <div>
      <div className="flex justify-between mb-1">
        <span className="bold dark:text-base dark:text-purple-700 dark:text-white">{name}</span>
        <span className="text-sm font-medium text-purple-700 dark:text-white">{percentage}</span>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mb-4">
        <div className="bg-purple-600 h-2.5 rounded-full" style={{ width: `${percentage}` }}></div>
      </div>
      </div>
   ))}
  </div>
  <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
    <Profeciency />
  </div>
</div>
<section id="posts">
<div className="py-24 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
  <h1 className="max-w-2xl mb-8 text-4xl tracking-tight font-extrabold text-gray-900 text-purple-600">Projects</h1>
  <ol className="relative border-s">
      {posts.post.map(({url, name, role, dates, description}) => (
        <li className="mb-10 ms-6">
          <span className="absolute flex items-center justify-center w-6 h-6 bg-purple-100 rounded-full -start-3 dark:bg-purple-900">
            <svg className="w-2.5 h-2.5 text-purple-800 dark:text-purple-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
            </svg>
          </span>
          <a href={url} className="flex items-center mb-1">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white hover:underline decoration-pruple-900 dark:decoration-gray-50">{name}</h3>
            <span className="bg-purple-100 text-purple-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300 ms-3">{role}</span>
          </a>
          <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-700">{dates}</time>
          <p className="text-base font-normal text-gray-700 dark:text-gray-400">{description}</p>
        </li>
      ))}
    </ol>
  </div>
</div>
</section>
</section>
<section>
    <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
        <img className="w-full block" src="https://cdn.discordapp.com/attachments/882238073985990666/1249761573329965126/cert-removebg-preview.png?ex=66687aaa&is=6667292a&hm=d3afcb5c64ddefb86d65adbd6275f6fe1c30f6bbcaad560c8b722b8a9c29745d&" />
        <div className="mt-4 md:mt-0">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-purple-600">IPV6 Certification</h2>
            <p className="mb-6 bold dark:font-light text-gray-500 dark:text-gray-200 md:text-lg ">Hurricane Electric IPv6 Certification sage certification</p>
            <a href="https://cdn.astroman.dev/u/UMQRys.pdf" className="inline-flex items-center text-white bg-purple-600 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900">
                Check certificate
                <svg className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </a>
        </div>
    </div>
</section>
<section id="contact">
<div className="grid max-w-screen-xl px-4 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
  <div className="mr-auto place-self-center lg:col-span-7">
    <h1 className="max-w-2xl mb-4 text-4xl tracking-tight font-extrabold text-gray-900 text-purple-600">Contact me!</h1>
    <p className="max-w-2xl mb-6 bold dark:font-light text-gray-700 lg:mb-2 md:text-lg lg:text-xl dark:text-gray-200">
      {contact.description}
    </p>
    <a href="mailto:shams@astroman.dev">
      <p className="max-w-2xl mb-6 font-bold text-gray-700 lg:mb-8 md:text-lg lg:text-3xl dark:text-gray-200 hover:underline decoration-purple-600 hover:text-5xl">
        {contact.mail}
      </p>
    </a>
    <div className="flex space-x-4">
      <a href="https://github.com/astromandev" className="text-gray-700 hover:text-gray-700">
        <FontAwesomeIcon icon={faGithub} size="2x" />
      </a>
      <a href="https://www.linkedin.com/in/shams-hanna-a7694b20a/" className="text-gray-700 hover:text-blue-700">
        <FontAwesomeIcon icon={faLinkedin} size="2x" />
      </a>
      <a href="https://discord.com/users/996585491606536192" className="text-gray-700 hover:text-blue-500">
        <FontAwesomeIcon icon={faDiscord} size="2x" />
      </a>
    </div>
  </div>
  <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
    <Email />
  </div>
</div>
</section>
      <div
        className="fixed inset-x-0 top-[calc(100%-13rem)] pointer-events-none
        transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" 
        style={{zIndex: -50}}
      >
        <div
          className="relative pointer-events-none
          left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#fa0f65] to-[#4c00ff] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </div>
  </div>
  );
}

export default App;
