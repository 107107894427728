import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileContract } from '@fortawesome/free-solid-svg-icons'

const TermsOfSale: React.FC = () => {
  return (
    <div className="bg-zinc-900 text-gray-300 min-h-screen">
      <div className="blob relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute pointer-events-none inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative pointer-events-none left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#4c00ff] to-[#e83838] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-4xl py-16">
          <h1 className="text-4xl font-extrabold tracking-tight text-purple-400 mb-8">Terms of Sale</h1>
          <div className="bg-zinc-800 shadow-xl rounded-lg overflow-hidden">
            <div className="p-6 sm:p-10">
              <div className="flex items-center mb-6">
                <FontAwesomeIcon icon={faFileContract} className="text-purple-400 text-3xl mr-4" />
                <h2 className="text-2xl font-bold text-white">Astroman Dev</h2>
              </div>
              <div className="space-y-6">
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">1. Products and Services</h3>
                  <p>Astroman Dev sells a range of products and services including BGP IP transits, Virtual Machines (VMs), and other networking and VPS-related products. By placing an order, the Client agrees to the following terms.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">2. Pricing and Payment</h3>
                  <p>All prices are listed in Euros (€) and exclude VAT unless otherwise stated. Payment must be made upfront, and Astroman Dev accepts payments via wire transfer or credit card. Services will only commence once full payment is received. Non-payment may result in service suspension or termination.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">3. Refunds</h3>
                  <p>No refunds will be provided once services are delivered unless explicitly agreed upon in writing. Clients must review our Refund Policy for further details.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">4. Order Acceptance</h3>
                  <p>Astroman Dev reserves the right to refuse or cancel any order for any reason, including pricing errors or stock availability. If an order is canceled after payment is received, a full refund will be issued.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">5. Delivery</h3>
                  <p>Service delivery timelines are estimates and not guaranteed. Astroman Dev will make best efforts to provision services as soon as possible.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">6. Warranties and Liability</h3>
                  <p>Astroman Dev provides services on an "as-is" basis, without warranties of any kind. The Company's liability for any claim related to the sale of products or services shall not exceed the purchase price paid by the Client.</p>
                </section>
              </div>
              <div className="mt-8 text-sm text-gray-400">
                <p>Company Name: Astroman Dev</p>
                <p>Address: 10 rue Cambon, 75001 Paris, France</p>
                <p>SIRET: 932456627</p>
                <p>VAT Number: FR85932456627</p>
                <p>Email: support@astroman.dev</p>
              </div>
              <p className="text-sm text-gray-400 mt-4">Last updated: September 18, 2024</p>
            </div>
          </div>
        </div>
        <div
          className="fixed inset-x-0 top-[calc(100%-13rem)] pointer-events-none transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          style={{zIndex: -50}}
        >
          <div
            className="relative pointer-events-none left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#fa0f65] to-[#4c00ff] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default TermsOfSale