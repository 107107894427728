import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons'

const Aup: React.FC = () => {
  return (
    <div className="bg-zinc-900 text-gray-300 min-h-screen">
      <div className="blob relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute pointer-events-none inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative pointer-events-none left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#4c00ff] to-[#e83838] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-4xl py-16">
          <h1 className="text-4xl font-extrabold tracking-tight text-purple-400 mb-8">Acceptable Use Policy (AUP)</h1>
          <div className="bg-zinc-800 shadow-xl rounded-lg overflow-hidden">
            <div className="p-6 sm:p-10">
              <div className="flex items-center mb-6">
                <FontAwesomeIcon icon={faShieldAlt} className="text-purple-400 text-3xl mr-4" />
                <h2 className="text-2xl font-bold text-white">Astroman Dev</h2>
              </div>
              <div className="space-y-6">
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">1. General Overview</h3>
                  <p>This Acceptable Use Policy (AUP) outlines the acceptable usage of Astroman Dev's services and applies to all clients, users, and affiliates. The goal is to ensure the integrity, security, and lawful use of our services.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">2. Prohibited Activities</h3>
                  <p>You may not use Astroman Dev's services to engage in activities that:</p>
                  <ul className="list-disc pl-6 space-y-2 mt-2">
                    <li>Violate any applicable local, state, national, or international law.</li>
                    <li>Distribute, transmit, or store illegal or unauthorized content, such as malware, viruses, phishing attacks, or pirated software.</li>
                    <li>Engage in hacking, cyber-attacks, DDoS (Distributed Denial of Service) attacks, or any actions that disrupt, interfere with, or degrade service to other users.</li>
                    <li>Send unsolicited bulk messages (spam) via email or other communication platforms.</li>
                    <li>Impersonate any person or entity or misrepresent your affiliation with any person or entity.</li>
                  </ul>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">3. Resource Abuse</h3>
                  <p>Excessive use of system resources, such as CPU, memory, bandwidth, or disk space, that affects other users' ability to access services is prohibited. Astroman Dev reserves the right to throttle or limit resource usage in such cases.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">4. Security Violations</h3>
                  <p>You must not attempt to access, probe, or breach the security of Astroman Dev's network, systems, or any other services provided by the company. Violations of system or network security may result in immediate termination of your services and possible legal action.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">5. Consequences of Violation</h3>
                  <p>Astroman Dev reserves the right to investigate violations of this AUP. Violations may result in the suspension or termination of services, reporting to relevant authorities, and/or legal action. We may also apply additional charges for resolving AUP violations.</p>
                </section>
              </div>
              <div className="mt-8 text-sm text-gray-400">
                <p>Company Name: Astroman Dev</p>
                <p>Address: 10 rue Cambon, 75001 Paris, France</p>
                <p>SIRET: 932456627</p>
                <p>VAT Number: FR85932456627</p>
                <p>Email: support@astroman.dev</p>
              </div>
              <p className="text-sm text-gray-400 mt-4">Last updated: June 15, 2023</p>
            </div>
          </div>
        </div>
        <div
          className="fixed inset-x-0 top-[calc(100%-13rem)] pointer-events-none transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          style={{zIndex: -50}}
        >
          <div
            className="relative pointer-events-none left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#fa0f65] to-[#4c00ff] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Aup