import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Nav from './components/nav';
import Footer from './components/footer';

import Tos from './tos.tsx';
import TermsOfSale from './termofsale.tsx';
import Aup from './aup.tsx';
import Nup from './nup.tsx';
import Sla from './sla.tsx';
import Fup from './fup.tsx';

import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <>
      <Nav />
      <Outlet />
      <Footer />
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<App />} />
        </Route>
        <Route path="/aup" element={<Aup />} />
        <Route path="/fup" element={<Fup />} />
        <Route path="/tos" element={<Tos />} />
        <Route path="/TermOfSale" element={<TermsOfSale />} />
        <Route path="/sla" element={<Sla />} />
        <Route path="/nup" element={<Nup />} />
      </Routes>
    </Router>
  </React.StrictMode>
);