import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="bg-white dark:bg-zinc-900 duration-[1000ms]">
            <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
                <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
                    © 2023-2024 All Rights Reserved.
                </span>
                <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
                    <li>
                        <Link to="/" className="hover:underline me-4 md:me-6">Home</Link>
                    </li>
                    <li>
                        <Link to="/aup" className="hover:underline me-4 md:me-6">AUP</Link>
                    </li>
                    <li>
                        <Link to="/fup" className="hover:underline me-4 md:me-6">FUP</Link>
                    </li>
                    <li>
                        <Link to="/tos" className="hover:underline me-4 md:me-6">TOS</Link>
                    </li>
                    <li>
                        <Link to="/TermOfSale" className="hover:underline me-4 md:me-6">Terms of Sale</Link>
                    </li>
                    <li>
                        <Link to="/sla" className="hover:underline me-4 md:me-6">SLA</Link>
                    </li>
                    <li>
                        <Link to="/nup" className="hover:underline me-4 md:me-6">NUP</Link>
                    </li>
                    <li>
                        <a href="#contact" className="hover:underline me-4 md:me-6">Contact</a>
                    </li>
                </ul>
            </div>
        </footer>
    );
}

export default Footer;