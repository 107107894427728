import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandshake } from '@fortawesome/free-solid-svg-icons'

const Sla: React.FC = () => {
  return (
    <div className="bg-zinc-900 text-gray-300 min-h-screen">
      <div className="blob relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute pointer-events-none inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative pointer-events-none left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#4c00ff] to-[#e83838] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-4xl py-16">
          <h1 className="text-4xl font-extrabold tracking-tight textpurple-400 mb-8">Service Level Agreement (SLA)</h1>
          <div className="bg-zinc-800 shadow-xl rounded-lg overflow-hidden">
            <div className="p-6 sm:p-10">
              <div className="flex items-center mb-6">
                <FontAwesomeIcon icon={faHandshake} className="text-purple-400 text-3xl mr-4" />
                <h2 className="text-2xl font-bold text-white">Astroman Dev</h2>
              </div>
              <div className="space-y-6">
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">1. Overview</h3>
                  <p>This Service Level Agreement (SLA) defines the expected service levels provided by Astroman Dev. This SLA applies only to clients with active services such as BGP IP transits, VMs, or VPS products.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">2. Service Performance</h3>
                  <p>Astroman Dev offers best-effort service with no guarantee of 100% uptime. We aim to provide reliable services but do not warrant uninterrupted availability. Service performance may be affected by external factors such as network congestion, cyber attacks, or maintenance.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">3. Uptime Commitment</h3>
                  <p>Astroman Dev targets an uptime of 99.5% for core services. This uptime does not apply to scheduled maintenance, which is communicated to clients in advance. Unplanned outages due to external events (e.g., DDoS attacks, third-party disruptions) are excluded from the uptime calculation.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">4. Response Times</h3>
                  <p>Astroman Dev will make best efforts to respond to technical support requests within the following time frames:</p>
                  <ul className="list-disc pl-6 space-y-2 mt-2">
                    <li>Critical Issues (Service Outage): 4-hour response time, 24/7.</li>
                    <li>Non-Critical Issues: Response within 24 hours during business hours (9 AM - 6 PM CET).</li>
                  </ul>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">5. Maintenance</h3>
                  <p>Astroman Dev reserves the right to perform scheduled maintenance with 24 hours' notice. Emergency maintenance may be performed with limited or no notice to preserve network integrity.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">6. Remedies</h3>
                  <p>Astroman Dev does not provide financial compensation or service credits for service disruptions. The Client acknowledges that the Company's services are provided on a best-effort basis, and remedies for service interruptions are limited to addressing the issue in a timely manner.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">7. Client Responsibilities</h3>
                  <p>The Client is responsible for the proper configuration and use of services. Any misuse or unauthorized alterations to service configurations are the sole responsibility of the Client and may result in service degradation or downtime.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">8. Exclusions</h3>
                  <p>The SLA does not apply in cases of:</p>
                  <ul className="list-disc pl-6 space-y-2 mt-2">
                    <li>Client's network or system issues.</li>
                    <li>Force Majeure events, including but not limited to natural disasters, strikes, or government actions.</li>
                    <li>Third-party outages affecting the service.</li>
                  </ul>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">9. Force Majeure</h3>
                  <p>Astroman Dev shall not be liable for any failure or delay in performing its obligations under this SLA if such delay is due to causes beyond its reasonable control, including but not limited to natural disasters, government actions, acts of terrorism, strikes, and other unforeseeable events. In such cases, Astroman Dev will make reasonable efforts to resume normal operations as soon as possible but does not guarantee service continuity during these events.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">10. Service Monitoring</h3>
                  <p>Astroman Dev uses a combination of internal and third-party monitoring systems to track the availability and performance of its services. While these monitoring systems provide valuable insights, minor discrepancies between the Client's perceived uptime and the monitoring data may occur. Clients are encouraged to report service disruptions for prompt investigation.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">11. Termination and Amendments</h3>
                  <p>Astroman Dev reserves the right to amend this SLA at any time. In such cases, Clients will be notified in writing of the changes, and the updated SLA will take effect immediately for all ongoing and new services. Clients may terminate their services if they do not agree with the amended terms, subject to the applicable cancellation policies as outlined in the Terms of Service.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">12. Limitation of Liability</h3>
                  <p>Astroman Dev's liability for any claims arising from service interruptions or performance issues shall be limited to direct damages proven to be caused by the Company's gross negligence or intentional misconduct. In no event shall the Company be liable for any indirect, incidental, punitive, or consequential damages, including but not limited to lost profits, lost revenue, or business interruptions, even if such damages were foreseeable.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">13. Governing Law</h3>
                  <p>This SLA is governed by and construed in accordance with the laws of France. Any disputes arising from this agreement shall be subject to the exclusive jurisdiction of the courts of Paris, France.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">14. Contact Information</h3>
                  <p>For any inquiries or support related to this SLA, please contact Astroman Dev at support@astroman.dev.</p>
                </section>
              </div>
              <p className="text-sm text-gray-400 mt-8">Last updated: September 18, 2024</p>
            </div>
          </div>
        </div>
        <div
          className="fixed inset-x-0 top-[calc(100%-13rem)] pointer-events-none transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          style={{zIndex: -50}}
        >
          <div
            className="relative pointer-events-none left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#fa0f65] to-[#4c00ff] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Sla