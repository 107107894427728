import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons'

const Fup: React.FC = () => {
  return (
    <div className="bg-zinc-900 text-gray-300 min-h-screen">
      <div className="blob relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute pointer-events-none inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative pointer-events-none left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#4c00ff] to-[#e83838] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-4xl py-16">
          <h1 className="text-4xl font-extrabold tracking-tight text-purple-400 mb-8">Fair Use Policy (FUP)</h1>
          <div className="bg-zinc-800 shadow-xl rounded-lg overflow-hidden">
            <div className="p-6 sm:p-10">
              <div className="flex items-center mb-6">
                <FontAwesomeIcon icon={faBalanceScale} className="text-purple-400 text-3xl mr-4" />
                <h2 className="text-2xl font-bold text-white">Astroman Dev</h2>
              </div>
              <div className="space-y-6">
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">1. Introduction</h3>
                  <p>This Fair Use Policy (FUP) ensures that all clients can enjoy high-quality services under fair and equitable conditions. The purpose of this FUP is to prevent excessive or improper use of resources that may negatively impact other clients.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">2. Scope</h3>
                  <p>Astroman Dev offers services such as BGP IP transits, VPS, and VMs with certain resource allocations (bandwidth, CPU, storage, etc.). This FUP applies to all users of these services to ensure responsible resource consumption.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">3. Fair Usage Limits</h3>
                  <ul className="list-disc pl-6 space-y-2">
                    <li><span className="font-semibold">Bandwidth:</span> Astroman Dev provides generous bandwidth limits. However, excessive usage that impairs network performance or impacts other clients may result in throttling or limiting of services.</li>
                    <li><span className="font-semibold">CPU and Memory:</span> Prolonged use of excessive CPU or memory resources, particularly with VPS or VM services, is considered unfair use and may result in service suspension or restriction.</li>
                  </ul>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">4. Excessive Usage</h3>
                  <p>If a user exceeds the fair usage limits, Astroman Dev will notify the client and may take action to mitigate the impact, including temporary service throttling, resource limitations, or, in extreme cases, service suspension.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">5. Right to Review</h3>
                  <p>Astroman Dev reserves the right to review and update this FUP at any time and will inform users of significant changes. Continued use of services constitutes acceptance of this FUP.</p>
                </section>
              </div>
              <div className="mt-8 text-sm text-gray-400">
                <p>Company Name: Astroman Dev</p>
                <p>Address: 10 rue Cambon, 75001 Paris, France</p>
                <p>SIRET: 932456627</p>
                <p>VAT Number: FR85932456627</p>
                <p>Email: support@astroman.dev</p>
              </div>
              <p className="text-sm text-gray-400 mt-4">Last updated: June 15, 2023</p>
            </div>
          </div>
        </div>
        <div
          className="fixed inset-x-0 top-[calc(100%-13rem)] pointer-events-none transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          style={{zIndex: -50}}
        >
          <div
            className="relative pointer-events-none left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#fa0f65] to-[#4c00ff] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Fup