import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons'

const Nup: React.FC = () => {
  return (
    <div className="bg-zinc-900 text-gray-300 min-h-screen">
      <div className="blob relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute pointer-events-none inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative pointer-events-none left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#4c00ff] to-[#e83838] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-4xl py-16">
          <h1 className="text-4xl font-extrabold tracking-tight text-purple-400 mb-8">Network Use Policy</h1>
          <div className="bg-zinc-800 shadow-xl rounded-lg overflow-hidden">
            <div className="p-6 sm:p-10">
              <div className="flex items-center mb-6">
                <FontAwesomeIcon icon={faNetworkWired} className="text-purple-400 text-3xl mr-4" />
                <h2 className="text-2xl font-bold text-white">Astroman Dev</h2>
              </div>
              <div className="space-y-6">
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">1. Introduction</h3>
                  <p>This Network Use Policy governs the appropriate use of Astroman Dev's network infrastructure, including BGP IP transits, and VPS-related services. It ensures network stability, security, and the efficient use of resources.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">2. Permitted Network Use</h3>
                  <p>The use of Astroman Dev's network must comply with all applicable laws and regulations. Users are prohibited from:</p>
                  <ul className="list-disc pl-6 space-y-2 mt-2">
                    <li>IP Spoofing: Using false or misleading IP addresses in network communications.</li>
                    <li>BGP Hijacking: Announcing or accepting IP prefixes that do not belong to you without authorization.</li>
                    <li>Network Abuse: Overloading the network through activities like bandwidth flooding or intentional route flapping.</li>
                    <li>Malicious Routing: Directing traffic through unauthorized or inappropriate routes.</li>
                  </ul>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">3. Traffic Filtering</h3>
                  <p>Astroman Dev reserves the right to monitor and filter traffic for security purposes, including identifying malicious activities and protecting the network from attacks such as DDoS. Legitimate traffic may be subject to filtering if necessary to maintain network stability.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">4. Traffic Prioritization</h3>
                  <p>Astroman Dev prioritizes traffic based on operational needs. Critical traffic (e.g., BGP announcements, core services) may take precedence over lower-priority services. Network abuse that causes instability may result in service limitations or termination.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">5. Non-Compliance</h3>
                  <p>Failure to comply with this policy may result in immediate suspension or termination of services, at Astroman Dev's discretion. In serious cases, legal action may be taken against violators.</p>
                </section>
              </div>
              <div className="mt-8 text-sm text-gray-400">
                <p>Company Name: Astroman Dev</p>
                <p>Address: 10 rue Cambon, 75001 Paris, France</p>
                <p>SIRET: 932456627</p>
                <p>VAT Number: FR85932456627</p>
                <p>Email: support@astroman.dev</p>
              </div>
              <p className="text-sm text-gray-400 mt-4">Last updated: September 18, 2024</p>
            </div>
          </div>
        </div>
        <div
          className="fixed inset-x-0 top-[calc(100%-13rem)] pointer-events-none transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          style={{zIndex: -50}}
        >
          <div
            className="relative pointer-events-none left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#fa0f65] to-[#4c00ff] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Nup