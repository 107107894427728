const hero = {
    Greeting: "Hello,",
    Name: "I'm Shams Hanna",
    Description: "A teenager currently residing in Paris, France. Born in Finland in 2009, I developed a passion for coding and computers at an early age. I began coding when I was 9 years old and created my first custom version of the Fedora operating system at the age of 10.."
};

const experience = {
    Description: 'In the past years I have gained experience in multiple fields such as:',
    experiences: [
        {
            svg:'<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m8 8-4 4 4 4m8 0 4-4-4-4m-2-3-4 14"/>',
            fill:'none', 
            experienceName: 'Backend Dev',
            experiencedescription: 'I am fluent with multiple backend coding languages, and I have participated in multiple backend based projects before.'
        },
        {
            svg:'<path fillRule="evenodd" d="M5 5a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2H5Zm9 2a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H14Zm3 0a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H17ZM3 17v-3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm11-2a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H14Zm3 0a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H17Z" clipRule="evenodd"/>',
            fill:'currentColor',
            experienceName: 'Infra Management',
            experiencedescription: 'Managing the infrastructure of multiple softwares allowed me to be able to administrate the performance and status of almost any software out there.'
        },
        {
            svg:'<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m16 10 3-3m0 0-3-3m3 3H5v3m3 4-3 3m0 0 3 3m-3-3h14v-3"/>',
            fill:'none',
            experienceName: 'Third Party Integration',
            experiencedescription: 'Integrating third party cloud services such as AWS and CloudFlare'
        },
        {
            svg:'<path fillRule="evenodd" d="M7 6a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-2v-4a3 3 0 0 0-3-3H7V6Z" clipRule="evenodd"/><path fillRule="evenodd" d="M2 11a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-7Zm7.5 1a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z" clipRule="evenodd"/><path d="M10.5 14.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"/>',
            fill:'currentColor',
            experienceName: 'Billing Panel Intergrations',
            experiencedescription: 'Installing and Setting up billing panels such as blesta and WHCMS for your company/business.'
        },
        {
            svg:'<path d="M12 7.205c4.418 0 8-1.165 8-2.602C20 3.165 16.418 2 12 2S4 3.165 4 4.603c0 1.437 3.582 2.602 8 2.602ZM12 22c4.963 0 8-1.686 8-2.603v-4.404c-.052.032-.112.06-.165.09a7.75 7.75 0 0 1-.745.387c-.193.088-.394.173-.6.253-.063.024-.124.05-.189.073a18.934 18.934 0 0 1-6.3.998c-2.135.027-4.26-.31-6.3-.998-.065-.024-.126-.05-.189-.073a10.143 10.143 0 0 1-.852-.373 7.75 7.75 0 0 1-.493-.267c-.053-.03-.113-.058-.165-.09v4.404C4 20.315 7.037 22 12 22Zm7.09-13.928a9.91 9.91 0 0 1-.6.253c-.063.025-.124.05-.189.074a18.935 18.935 0 0 1-6.3.998c-2.135.027-4.26-.31-6.3-.998-.065-.024-.126-.05-.189-.074a10.163 10.163 0 0 1-.852-.372 7.816 7.816 0 0 1-.493-.268c-.055-.03-.115-.058-.167-.09V12c0 .917 3.037 2.603 8 2.603s8-1.686 8-2.603V7.596c-.052.031-.112.059-.165.09a7.816 7.816 0 0 1-.745.386Z"/>',
            fill:'currentColor',
            experienceName: 'System Administration',
            experiencedescription: 'Setting up BGP Router And establishing v4 and v6 sessions whit peers, upstreams, routerservers and downstreams and system administrating your linux based OS'
        },
      ],
};

const proficiency = {
    Description: 'Throughout my career, I have consistently demonstrated a high level of expertise in various programming languages and technologies. Here is a snapshot of my proficiency in some key areas.',
    skill: [ 
    {
        name: 'backend development',
        percentage: '100%'
     },
     {
        name: 'Infra management',
        percentage: '83%'
     },
     {
        name: 'BGP Networking',
        percentage: '68%'
     },
    ],
  };

const posts = {
    post: [
            {
              name: "Argon Hosting(Closed Down)",
              url: "#",
              role: "CTO",
              dates: "May 2022 - May 2024",
              description: "I recently joined Argon Hosting, a company dedicated to delivering top-notch servers. Our commitment revolves around consistently offering exceptional support and ensuring the provision of the highest quality servers."
            },
            {
              name: "Obsidian Host(Closed Down)",
              url: "#",
              role: "Operations manager",
              dates: "Apr 2024 - May 2024",
              description: "I was hired to help them plan and develop their company."
            },
            {
              name: "AS215605",
              url: "https://as215605.tech/",
              role: "Owner",
              dates: "Jan 2024 - Present",
              description: "I opened AS215605 to start my own BGP network and learn more about BGP networking."
            },
            {
              name: "Astroman Dev",
              url: "https://astroman.dev/",
              role: "Founder",
              dates: "Oct 2023 - Present",
              description: "I registered Astroman Dev to start freelancing and selling my services to companies and individuals."
            },
            {
              name: "TechMentorTeens",
              url: "https://techmentorteens.org/",
              role: "President",
              dates: "Jun 2024 - Present",
              description: "TechMentor Teen is a new Association, located in France. We aim to focuses on supporting adolescents who have Programming and System Administration hobbies."
            }
    ],
};

const contact = {
    description: 'Want to discuss a project or just want to say hi? My inbox is open for all!',
    mail: 'shams@astroman.dev'
}

export {hero,experience, proficiency, posts, contact}