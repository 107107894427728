import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/free-solid-svg-icons'

const Tos: React.FC = () => {
  return (
    <div className="bg-zinc-900 text-gray-300 min-h-screen">
      <div className="blob relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute pointer-events-none inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative pointer-events-none left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#4c00ff] to-[#e83838] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-4xl py-16">
          <h1 className="text-4xl font-extrabold tracking-tight text-purple-400 mb-8">Terms of Service</h1>
          <div className="bg-zinc-800 shadow-xl rounded-lg overflow-hidden">
            <div className="p-6 sm:p-10">
              <div className="flex items-center mb-6">
                <FontAwesomeIcon icon={faBook} className="text-purple-400 text-3xl mr-4" />
                <h2 className="text-2xl font-bold text-white">Astroman Dev</h2>
              </div>
              <div className="space-y-6">
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">1. Acceptance of Terms</h3>
                  <p>By accessing or using the services provided by Astroman Dev (SIRET: 932456627, VAT: FR85932456627), you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our services.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">2. Description of Services</h3>
                  <p>Astroman Dev provides web development, consulting, and technical services. The specific details of services will be outlined in individual project agreements or statements of work.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">3. User Obligations</h3>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Provide accurate and complete information for project requirements.</li>
                    <li>Cooperate with Astroman Dev throughout the development process.</li>
                    <li>Respect intellectual property rights and confidentiality agreements.</li>
                    <li>Make timely payments as per the agreed payment schedule.</li>
                  </ul>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">4. Intellectual Property Rights</h3>
                  <p>Unless otherwise specified in writing, Astroman Dev retains all intellectual property rights to the code, designs, and other materials created during the course of service provision. Upon full payment, clients are granted a non-exclusive license to use the deliverables for their intended purpose.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">5. Payment Terms</h3>
                  <p>Payment terms will be specified in individual project agreements. Generally, a deposit is required before work begins, with remaining payments due at agreed-upon milestones or upon project completion.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">6. Confidentiality</h3>
                  <p>Astroman Dev agrees to keep all client information confidential and secure. Clients agree not to disclose any proprietary information about Astroman Dev's services or methodologies.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">7. Limitation of Liability</h3>
                  <p>Astroman Dev's liability is limited to the amount paid for the services. We are not responsible for any indirect, consequential, or incidental damages.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">8. Termination</h3>
                  <p>Either party may terminate the service agreement with written notice if the other party breaches any material provision of these terms. Termination does not relieve the client of the obligation to pay for services rendered.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">9. Governing Law</h3>
                  <p>These terms are governed by the laws of France. Any disputes shall be resolved in the courts of Paris, France.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">10. Changes to Terms</h3>
                  <p>Astroman Dev reserves the right to modify these terms at any time. Changes will be effective upon posting to our website. Continued use of our services constitutes acceptance of the modified terms.</p>
                </section>
                <section>
                  <h3 className="text-xl font-semibold text-purple-400 mb-2">11. Contact Information</h3>
                  <p>For any questions regarding these Terms of Service, please contact us at:</p>
                  <p className="mt-2">
                    Astroman Dev<br />
                    10 rue Cambon, 75001 Paris, France<br />
                    Email: support@astroman.dev
                  </p>
                </section>
              </div>
              <p className="text-sm text-gray-400 mt-8">Last updated: September 18, 2024</p>
            </div>
          </div>
        </div>
        <div
          className="fixed inset-x-0 top-[calc(100%-13rem)] pointer-events-none transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          style={{zIndex: -50}}
        >
          <div
            className="relative pointer-events-none left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#fa0f65] to-[#4c00ff] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Tos